// extracted by mini-css-extract-plugin
export var divider = "PlasmicPriceCard-module--divider--p-36O";
export var freeBox___8EXj = "PlasmicPriceCard-module--freeBox___8EXj--34Oa2";
export var freeBox__iHdxK = "PlasmicPriceCard-module--freeBox__iHdxK--2oJ0G";
export var freeBox__jwg3W = "PlasmicPriceCard-module--freeBox__jwg3W--DMmPp";
export var freeBox__kMvO = "PlasmicPriceCard-module--freeBox__kMvO--mS9Xw";
export var freeBox__p1Pbb = "PlasmicPriceCard-module--freeBox__p1Pbb--NUHOF";
export var freeBox__rgHzz = "PlasmicPriceCard-module--freeBox__rgHzz--GsJZ0";
export var freeBox__tv2Va = "PlasmicPriceCard-module--freeBox__tv2Va--+GxnD";
export var freeBox__zClxo = "PlasmicPriceCard-module--freeBox__zClxo--uwEyl";
export var root = "PlasmicPriceCard-module--root--Spbjy";
export var rootborders_flatLeft = "PlasmicPriceCard-module--rootborders_flatLeft---1osZ";
export var rootborders_flatRight = "PlasmicPriceCard-module--rootborders_flatRight--nWpKz";
export var rootprimary = "PlasmicPriceCard-module--rootprimary--hZpzg";
export var slotTargetDollars = "PlasmicPriceCard-module--slotTargetDollars--+nwnp";
export var slotTargetLabel = "PlasmicPriceCard-module--slotTargetLabel--eIsJc";
export var svg__angg9 = "PlasmicPriceCard-module--svg__angg9--uzJfb";
export var svg__jqGpj = "PlasmicPriceCard-module--svg__jqGpj--pN4Zn";
export var svg__tvIEs = "PlasmicPriceCard-module--svg__tvIEs--A1WBk";
export var svg__ulNTb = "PlasmicPriceCard-module--svg__ulNTb--hICsK";
export var text__b3QIo = "PlasmicPriceCard-module--text__b3QIo--9DkUW";
export var text__cXqQw = "PlasmicPriceCard-module--text__cXqQw--PTRaq";
export var text__rkVg = "PlasmicPriceCard-module--text__rkVg--UoDEz";
export var valueProp___156Qh = "PlasmicPriceCard-module--valueProp___156Qh--zzq0c";
export var valueProp__eefza = "PlasmicPriceCard-module--valueProp__eefza--FVwio";