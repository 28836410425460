// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--vKl2K";
export var authorPicture = "PlasmicTestimonial-module--authorPicture--5RdQU";
export var freeBox__gwIt1 = "PlasmicTestimonial-module--freeBox__gwIt1--jOn5A";
export var freeBox__kJaM = "PlasmicTestimonial-module--freeBox__kJaM--9ctI4";
export var freeBox__sQiux = "PlasmicTestimonial-module--freeBox__sQiux--B7vcJ";
export var freeBox__ua5E = "PlasmicTestimonial-module--freeBox__ua5E--OmpJG";
export var root = "PlasmicTestimonial-module--root--bCVjJ";
export var slotTargetName = "PlasmicTestimonial-module--slotTargetName--gn54N";
export var slotTargetQuote = "PlasmicTestimonial-module--slotTargetQuote--5qRwV";
export var slotTargetTitle = "PlasmicTestimonial-module--slotTargetTitle--rMuL9";