// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicCallToActionSection-module--__wab_img-spacer--UUvms";
export var art = "PlasmicCallToActionSection-module--art--Z1xhl";
export var blend = "PlasmicCallToActionSection-module--blend--pPXzu";
export var button__jehMv = "PlasmicCallToActionSection-module--button__jehMv--WjuLp";
export var button__zkurn = "PlasmicCallToActionSection-module--button__zkurn--NET4Y";
export var copy = "PlasmicCallToActionSection-module--copy--KqEhn";
export var freeBox___0QHwr = "PlasmicCallToActionSection-module--freeBox___0QHwr--7D24h";
export var freeBox___0UReV = "PlasmicCallToActionSection-module--freeBox___0UReV--zwkA6";
export var freeBox__jFi2D = "PlasmicCallToActionSection-module--freeBox__jFi2D--6phwO";
export var freeBox__slBeg = "PlasmicCallToActionSection-module--freeBox__slBeg--eQv4k";
export var freeBox__vahrf = "PlasmicCallToActionSection-module--freeBox__vahrf--PALxQ";
export var img = "PlasmicCallToActionSection-module--img--VtcWL";
export var root = "PlasmicCallToActionSection-module--root--j07KT";
export var svg___5Eo4O = "PlasmicCallToActionSection-module--svg___5Eo4O--PCmK6";
export var svg__epB2F = "PlasmicCallToActionSection-module--svg__epB2F--1Irls";
export var svg__jF7Rj = "PlasmicCallToActionSection-module--svg__jF7Rj--U4YaH";
export var svg__zKWuH = "PlasmicCallToActionSection-module--svg__zKWuH--66NCY";
export var text___5Th7Y = "PlasmicCallToActionSection-module--text___5Th7Y--yQkTD";
export var text__n42J = "PlasmicCallToActionSection-module--text__n42J--GdxJ-";
export var text__oIkHv = "PlasmicCallToActionSection-module--text__oIkHv--WXTNx";
export var text__q9A7 = "PlasmicCallToActionSection-module--text__q9A7--0kqVi";