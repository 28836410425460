const handleDemoRequestButtons = () => {
  const buttons = document.getElementsByTagName("button")

  for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", event => {
      if (event.target.textContent === "Prenota una demo") {
        window.location.href = "/demo"
      }
    })
  }
}

export default handleDemoRequestButtons
