// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicValueProp-module--__wab_img-spacer--gz2+Z";
export var contentContainer = "PlasmicValueProp-module--contentContainer--F6y7z";
export var iconContainer = "PlasmicValueProp-module--iconContainer--W-EjX";
export var iconContainercard = "PlasmicValueProp-module--iconContainercard--YGIq2";
export var iconContainerflatIcon = "PlasmicValueProp-module--iconContainerflatIcon--jZjpW";
export var img__sgtH6 = "PlasmicValueProp-module--img__sgtH6--6Wi9p";
export var root = "PlasmicValueProp-module--root--lpIJt";
export var rootcard = "PlasmicValueProp-module--rootcard--YhVXB";
export var rootvertical = "PlasmicValueProp-module--rootvertical--XU6NX";
export var slotTargetDescription = "PlasmicValueProp-module--slotTargetDescription--vJf5O";
export var slotTargetDescriptiondarkBackground = "PlasmicValueProp-module--slotTargetDescriptiondarkBackground--akjVl";
export var slotTargetTitle = "PlasmicValueProp-module--slotTargetTitle--HWoXQ";
export var slotTargetTitledarkBackground = "PlasmicValueProp-module--slotTargetTitledarkBackground--ScoYH";